import revive_payload_client__eUl7O5OvWz89Te9dm1hC7jaa1xA13hkVhpDqcgDRf4 from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.3.1_eslint@9.23.0_jiti@2.4_9b3ff5388459c206b838bd1f5e77bf68/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_6u6eWRVWzK53riP92llMIMQyklKk_owvJt0MGrTpgtg from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.3.1_eslint@9.23.0_jiti@2.4_9b3ff5388459c206b838bd1f5e77bf68/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yS46YaQTXmA2bH_x_oqe4i6NxF3oMqEnv3QXeJHck6I from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.3.1_eslint@9.23.0_jiti@2.4_9b3ff5388459c206b838bd1f5e77bf68/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_5_Df5MibenU9q7hOWAlwbNrMpfjqEZ03E35FowsCVs8 from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_ToCT2VVt1spVzzNue4B4QCZ_lV_GWX1RtYcDOY6T3cA from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.3.1_eslint@9.23.0_jiti@2.4_9b3ff5388459c206b838bd1f5e77bf68/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3rgltlW1tfj6SogYenMSPoviqLw9VGtZI3ELMXQw0dc from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.3.1_eslint@9.23.0_jiti@2.4_9b3ff5388459c206b838bd1f5e77bf68/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_4BKNvsHl1g70d_Unm7r_HEQoiVK7hOQG8qj97_x5Wcw from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.3.1_eslint@9.23.0_jiti@2.4_9b3ff5388459c206b838bd1f5e77bf68/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_R0Pv_6N_zVERGqQuGvtLJ0SYq7_gHVleNX83AJjhSvQ from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.3.1_eslint@9.23.0_jiti@2.4_9b3ff5388459c206b838bd1f5e77bf68/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_yyPFIJnMrljikG8d6c41JgG8rbOVUrEPbL80wKE56OI from "/home/vsts/work/1/s/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/vsts/work/1/s/apps/lobby/.nuxt/components.plugin.mjs";
import prefetch_client_8dFniRZS0WCkSO0LR8wc_s8cZVW9D_zolG5ZCG47CSA from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.3.1_eslint@9.23.0_jiti@2.4_9b3ff5388459c206b838bd1f5e77bf68/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_vpNs8i0cr9N1X_z0gnT94vc4uwUE_tOHJq1wXR6Srws from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_itgMVrkWhkdEQJvEbuWBZoH6BbjNbcCBdYTxzR2TWE4 from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_ca968f710b3ea4e487cc17155de6d0b4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_6Cnyxkw872a6veuP2Hm4tDM2JSwwIp8ddt5hiHnz_ng from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_ca968f710b3ea4e487cc17155de6d0b4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_vXH5Ut8ph4nyHAcBfmBKyqgBy36rZw9Uhv6HPdEXfdU from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_ca968f710b3ea4e487cc17155de6d0b4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_7sOGQS2heG1rbXP5yUuHiugj44qTAzqzIcTMUuYF22o from "/home/vsts/work/1/s/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8_56bc314c1b314c94013faae7bd392089/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import agile_Yl99j92dr2HkKBq8ZM69Q_6bTXQQA7KAn_rctSyE2YA from "/home/vsts/work/1/s/apps/lobby/plugins/agile.js";
import auth0_aw5eaEvpcxQNiQnvHlR9sjubciScvAkTlW5MFf5za4c from "/home/vsts/work/1/s/apps/lobby/plugins/auth0.js";
import base64_NBeUjxfnqeHAnJnJfx2qPiCTldh7mjF3nAnlbt5cM7Y from "/home/vsts/work/1/s/apps/lobby/plugins/base64.ts";
import contentful_JjzTXUmvCjDdSpGWWLsQoONYNp3kTLa0BNNJH1B4UzY from "/home/vsts/work/1/s/apps/lobby/plugins/contentful.ts";
import cookies_qNgDThwaAi1YCLYpH1J2CZfS6e11vpSTsXrX1LxB9D4 from "/home/vsts/work/1/s/apps/lobby/plugins/cookies.js";
import cryptojs_9OgTEtjG0Jx3_Z62Ivs5YEu1Rc18TrapAJBm4tLq5Bk from "/home/vsts/work/1/s/apps/lobby/plugins/cryptojs.js";
import environment__YWu8xwk_pMwj62R9No_rIdc5UzxP_Zt_OART1HvLPU from "/home/vsts/work/1/s/apps/lobby/plugins/environment.ts";
import goodbits_api_g_vdZD9KFHvgAk49QSZOVP_Epj3lzAmJOhn_woDh6gU from "/home/vsts/work/1/s/apps/lobby/plugins/goodbits.api.ts";
import scriptLoader_uAK2yWN1uE99qC65Nbaj5DRD1YkE29NNmlD1Cuzax8Y from "/home/vsts/work/1/s/apps/lobby/plugins/scriptLoader.ts";
import translations_Xnn9oMPxMOX0f4szPS6HdoYKjy_RiXRLPYzb0JMamSo from "/home/vsts/work/1/s/apps/lobby/plugins/translations.js";
import v_calendar_client_71AR796SOk_SN91TmUE9gy9E3m857q1Jwla348H7qwY from "/home/vsts/work/1/s/apps/lobby/plugins/v-calendar.client.js";
import vue_screen_utils_client_IQfL_sTZh9LC1chwy57ckmvzOch1zCaqSNUq5AbnZcE from "/home/vsts/work/1/s/apps/lobby/plugins/vue-screen-utils.client.js";
import wmx_outside_client_71RQSSTAn0ZyeLq0hT6xJ68dAqCPo_KfSvBcnErhWeo from "/home/vsts/work/1/s/apps/lobby/plugins/wmx-outside.client.js";
import ssg_detect_wM9lzUg6JesWaaWWUSW766mRH8HqJgCIM5I3BgMCOJc from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_ca968f710b3ea4e487cc17155de6d0b4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client__eUl7O5OvWz89Te9dm1hC7jaa1xA13hkVhpDqcgDRf4,
  unhead_6u6eWRVWzK53riP92llMIMQyklKk_owvJt0MGrTpgtg,
  router_yS46YaQTXmA2bH_x_oqe4i6NxF3oMqEnv3QXeJHck6I,
  _0_siteConfig_5_Df5MibenU9q7hOWAlwbNrMpfjqEZ03E35FowsCVs8,
  payload_client_ToCT2VVt1spVzzNue4B4QCZ_lV_GWX1RtYcDOY6T3cA,
  navigation_repaint_client_3rgltlW1tfj6SogYenMSPoviqLw9VGtZI3ELMXQw0dc,
  check_outdated_build_client_4BKNvsHl1g70d_Unm7r_HEQoiVK7hOQG8qj97_x5Wcw,
  chunk_reload_client_R0Pv_6N_zVERGqQuGvtLJ0SYq7_gHVleNX83AJjhSvQ,
  plugin_vue3_yyPFIJnMrljikG8d6c41JgG8rbOVUrEPbL80wKE56OI,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_8dFniRZS0WCkSO0LR8wc_s8cZVW9D_zolG5ZCG47CSA,
  i18n_vpNs8i0cr9N1X_z0gnT94vc4uwUE_tOHJq1wXR6Srws,
  switch_locale_path_ssr_itgMVrkWhkdEQJvEbuWBZoH6BbjNbcCBdYTxzR2TWE4,
  route_locale_detect_6Cnyxkw872a6veuP2Hm4tDM2JSwwIp8ddt5hiHnz_ng,
  i18n_vXH5Ut8ph4nyHAcBfmBKyqgBy36rZw9Uhv6HPdEXfdU,
  plugin_7sOGQS2heG1rbXP5yUuHiugj44qTAzqzIcTMUuYF22o,
  agile_Yl99j92dr2HkKBq8ZM69Q_6bTXQQA7KAn_rctSyE2YA,
  auth0_aw5eaEvpcxQNiQnvHlR9sjubciScvAkTlW5MFf5za4c,
  base64_NBeUjxfnqeHAnJnJfx2qPiCTldh7mjF3nAnlbt5cM7Y,
  contentful_JjzTXUmvCjDdSpGWWLsQoONYNp3kTLa0BNNJH1B4UzY,
  cookies_qNgDThwaAi1YCLYpH1J2CZfS6e11vpSTsXrX1LxB9D4,
  cryptojs_9OgTEtjG0Jx3_Z62Ivs5YEu1Rc18TrapAJBm4tLq5Bk,
  environment__YWu8xwk_pMwj62R9No_rIdc5UzxP_Zt_OART1HvLPU,
  goodbits_api_g_vdZD9KFHvgAk49QSZOVP_Epj3lzAmJOhn_woDh6gU,
  scriptLoader_uAK2yWN1uE99qC65Nbaj5DRD1YkE29NNmlD1Cuzax8Y,
  translations_Xnn9oMPxMOX0f4szPS6HdoYKjy_RiXRLPYzb0JMamSo,
  v_calendar_client_71AR796SOk_SN91TmUE9gy9E3m857q1Jwla348H7qwY,
  vue_screen_utils_client_IQfL_sTZh9LC1chwy57ckmvzOch1zCaqSNUq5AbnZcE,
  wmx_outside_client_71RQSSTAn0ZyeLq0hT6xJ68dAqCPo_KfSvBcnErhWeo,
  ssg_detect_wM9lzUg6JesWaaWWUSW766mRH8HqJgCIM5I3BgMCOJc
]